import React from "react";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Image from "common/components/Image";
import Logo from "common/components/UIElements/Logo";
import LogoImage from "images/smallLogo.png";
import whatsapp from "../../images/whatsapp.svg";
import facebook from "../../images/facebook.svg";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import FooterArea, {
  WidgetArea,
  MenuArea,
  Menu,
  MenuItem,
  CopyrightText,
} from "./footer.style";

import AppImage from "../../images/footerapp.svg";
import PlaystoreImage from "../../images/footerplay.svg";
import MaslolLogoAlt from "images/foterLogo.png";

const Footer = ({ lang = "He", logo = "DG" }) => {
  const menu = [
    {
      id: 1,
      text: "Home",
      link: "#",
    },
    {
      id: 2,
      text: "Adversite",
      link: "#",
    },
    {
      id: 3,
      text: "Supports",
      link: "#",
    },
    {
      id: 4,
      text: "Marketing",
      link: "#",
    },
    {
      id: 5,
      text: "Contact",
      link: "#",
    },
  ];

  const date = new Date();
  const year = date.getFullYear();

  const textLang = {
    whatsappTitle: {
      He: "התיעצות עם נציג",
      En: "Chat with us",
    },
    whatsappText: {
      He: "נציג שלנו זמים עבורכם מיידית לכל שאלה",
      En: "We have an instant chat via WhatsApp waiting to hear from you",
    },
    facebookTitle: {
      He: "הצטרפו לקהילה שלנו",
      En: "Join to our Community",
    },
    facebookText: {
      He: "הצטרפו לקהילה שלנו ותהיו הראשונים להתעדכן בסיורים חדשים בקופונים ומבצעים",
      En: "Join to our Community on Facebook and get updates about new tours and get discount coupons",
    },
    appsTitle: {
      He: "הורידו את האפליקציה",
      En: "Download our App",
    },
    appsText: {
      He: "כל הסיורים סביבך, בחרו את הסיור והמדריך והתחילו לטייל מיד",
      En: "Get all the tours around you, peak tour guide and start instant tour",
    },
  };

  return (
    <FooterArea>
      <Container>
        <WidgetArea>
          <Box className="col" key={`footer-widget--key-1`}>
            <a href="https://wa.me/972509341412">
              <Image src={whatsapp} alt="Conntact us via Whatsapp" />
            </a>
            <Heading as="h3" content={textLang["whatsappTitle"][lang]} />
            <Text content={textLang["whatsappText"][lang]} />
          </Box>
          <Box className="col" key={`footer-widget--key-2`}>
            <a href="https://www.facebook.com/DguideAPP">
              <Image src={facebook} alt="Join to our Community" />
            </a>
            <Heading as="h3" content={textLang["facebookTitle"][lang]} />
            <Text content={textLang["facebookText"][lang]} />
          </Box>
          <Box className="col" key={`footer-widget--key-3`}>
            <Box className="imageWrapper">
              <a href="https://apple.co/3u4oUSp">
                <Image src={AppImage} alt="App Image" />
              </a>

              <a href="https://bit.ly/3qqKOx5">
                <Image src={PlaystoreImage} alt="PlaystoreImage Image" />
              </a>
            </Box>
            <Heading as="h3" content={textLang["appsTitle"][lang]} />
            <Text content={textLang["appsText"][lang]} />
          </Box>
        </WidgetArea>
        {/* End of footer widgets area */}
        <MenuArea>
          <Logo
            className="logo"
            href="/appclassic"
            logoSrc={logo === "DG" ? LogoImage : MaslolLogoAlt}
            title="App Classic"
          />
          <Menu>
            {menu.map((item) => (
              <MenuItem key={`footer-link${item.id}`}>
                <a href={item.link}>{item.text}</a>
              </MenuItem>
            ))}
          </Menu>
          <CopyrightText>Copyright {year} By D-guide Inc</CopyrightText>
        </MenuArea>
        {/* End of footer menu area */}
      </Container>
    </FooterArea>
  );
};

export default Footer;
