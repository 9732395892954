const colors = {
  transparent: "transparent", // 0
  black: "#000000", // 1
  white: "#ffffff", // 2
  headingColor: "#302b4e",
  textColor: "#43414e", // 3
  labelColor: "#767676", // 4
  inactiveField: "#f2f2f2", // 5
  inactiveButton: "#b7dbdd", // 6
  inactiveIcon: "#EBEBEB", // 7
  primary: "#1d2336", //#ff4265', // 8
  primaryHover: "#1d2336", //"#ff4265", // 9
  secondary: "#E14A1B", //"#f05c2c", //"#edcd37", // 10
  secondaryHover: "#E14A1B", //"#f05c2c", //"#edcd37", // 11
  yellow: "#f05c2c", //"#fdb32a", // 12
  yellowHover: "#f05c2c", //"#F29E02", // 13
  borderColor: "#1b1e25", //14
  primaryBoxShadow: "0px 8px 20px -6px rgba(255, 66, 101, 0.57)",
  secondaryBoxShadow: "0px 8px 20px -6px rgba(237, 205, 55, 0.5)",
};

export default colors;
